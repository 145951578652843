var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.info
    ? _c("span", [
        _c(
          "div",
          { class: _vm.classes },
          [
            _c(
              "div",
              { staticClass: "icon" },
              [
                _vm.uploading
                  ? _c("b-spinner", { attrs: { label: "Small Spinner" } })
                  : _vm._e(),
                !_vm.uploading ? _c("i", { class: _vm.iconClass }) : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "information" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass: "name",
                  attrs: { title: _vm.info.name },
                },
                [_vm._v("\n        " + _vm._s(_vm.info.name) + "\n      ")]
              ),
              _vm.info.size
                ? _c("div", { staticClass: "filesize" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.bytesToSize(_vm.info.size)) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm.isUploaded && !_vm.isError
                ? _c(
                    "div",
                    { staticClass: "menu" },
                    [
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            size: "sm",
                            "toggle-class": "no-border",
                            "no-caret": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "button-content",
                                fn: function () {
                                  return [
                                    _c("i", {
                                      staticClass:
                                        "fa-regular fa-ellipsis clickable",
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1706905662
                          ),
                        },
                        [
                          _vm.showDownload
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "#" },
                                  on: { click: _vm.Download },
                                },
                                [_vm._v(_vm._s(_vm.$t("DOWNLOAD")))]
                              )
                            : _vm._e(),
                          _vm.showDownload
                            ? _c("b-dropdown-divider")
                            : _vm._e(),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { variant: "danger", href: "#" },
                              on: { click: _vm.Remove },
                            },
                            [_vm._v(_vm._s(_vm.$t("REMOVE")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isError
                ? _c("div", { staticClass: "remove" }, [
                    _c("i", {
                      staticClass: "fal fa-trash-alt pr-2 clickable",
                      on: {
                        click: function ($event) {
                          return _vm.Remove(_vm.index)
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ]),
            _vm.percentage > 0 && _vm.percentage < 100
              ? _c("b-progress", {
                  attrs: {
                    value: _vm.percentage,
                    max: 100,
                    "show-progress": "",
                    animated: "",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }