<template>
  <section>
    <b-form-textarea
      class="mt-2 p-2 textarea"
      id="messageContent"
      ref="messageContent"
      :placeholder="$t('CONTENT')"
      input-id="message"
      v-model="body"
      autocomplete="off"
      :rows="rows"
      :state="TextState"
      :max-rows="rows"
    ></b-form-textarea>

    <div class="clearfix">
      <div class="float-left">
        <b-form-text id="ENCRYPTED_BODY" v-if="!hideDescription">
          <i class="fa-light fa-lock-keyhole"></i>&nbsp;{{ $t("ENCRYPTED_BODY") }}
        </b-form-text>
        <div class="error-text" v-if="body.length > limit">
          {{ $t("ERROR.TOO_MANY_CHARACTERS") }}
        </div>
      </div>
      <div class="float-right">
        <div class="text-right">
          <small class="pull-right">{{ body.length }}/{{ limit }}</small>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    value: String,
    settings: {
      default: null,
      type: Object,
    },
    limit: {
      default: 20000,
      type: Number,
    },
    rows: {
      default: 8,
      type: Number,
    },
    hideDescription: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
  },
  data() {
    return {
      body: "",
    };
  },
  methods: {},
  computed: {
    TextState() {
      return this.body.length == 0 ? null : this.body.length <= this.limit;
    },
  },
  watch: {
    body() {
      this.$emit("valid", this.body.length <= this.limit);
      this.$emit("input", this.body);
    },
  },
  mounted() {
    this.body = this.value;
    if (this.body != "") {
      setTimeout(() => {
        let input = document.getElementById("messageContent");
        if (input != null) {
          input.setSelectionRange(0, 0);
        }
      }, 500);
    }
  },
};
</script>
