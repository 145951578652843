<template>
  <section :key="folderKey" v-if="loaded">

    <b-breadcrumb class="mt-3 mb-0">
      <b-breadcrumb-item
        href="#"
        v-for="(item, index) in breadcrumbs"
        :key="index"
        :active="item.active"
        @click="clickedFolder(item.folder_uuid)"
        >{{ item.text }}</b-breadcrumb-item
      >
    </b-breadcrumb>    
    
    <b-list-group class="mt-2">
      <b-list-group-item class="clickable" v-if="breadcrumbs.length > 1" @click="goBackOneLevel"> .. </b-list-group-item>
      <b-list-group-item class="clickable"  v-for="(item, index) in folders" :key="index"  @click="clickedFolder(item.folder_uuid)"  v-b-tooltip.hover :title="item.name"><i class="fal fa-2x fa-folder"></i> {{ Truncate(item.name,55) }} </b-list-group-item>
      <b-list-group-item v-if="folders.length == 0">{{ $t("FILESTORAGE.NO_FOLDERS_TO_SAVE_TO") }}</b-list-group-item>
    </b-list-group>
    
  </section>
</template>
<script>
export default {
  props: { 
    value: {
        default: "",
        type: String,
    },
    active: {
      default: "",
      type: String
    }
  },
  data() {
    return {
      loading: false,
      folders: [],
      crumbs: [],
      loaded: false
    };
  },
  methods: {
    clickedFolder(folder_uuid){
      this.$emit('input', folder_uuid);
    },
    goBackOneLevel(){
      let folder_uuid = this.breadcrumbs.slice(-2,-1)[0].folder_uuid;      
      this.$emit('input', folder_uuid);
    },
    getFolders: function () {
      let self = this;
      this.loading = true;
      let ListFoldersRequestDTO = {
        organisation_uuid: this.user.information.organisation.organisation_uuid,
        user_uuid: "",
        parent_uuid: this.value,
        search_text: "",
        page: 1,
        limit: 2000,
      };
      this.$http
        .post(
          this.user.hostname + "/filestorage/folder/list",
          ListFoldersRequestDTO
        )
        .then((response) => {
          self.redrawIndex = self.redrawIndex + 1;
          self.crumbs = response.data.breadcrums;
          self.folders = response.data.items.map((element) => {
            element.type = "folder";
            return element;
          });
          this.loaded = true;
        })
        .catch(() => {
          self.loading = false;
          this.loaded = true;
        });
    }
  },
  computed: {
    breadcrumbs() {
      let currentName = this.$t("FILESTORAGE.MY_FILES");
      let items = [];
      for (let ix = 0; ix < this.crumbs.length; ix++) {
        let crumb = this.crumbs[ix];
        if (crumb.name == "root") {
          items.push({
            folder_uuid: "",
            text: currentName,
            active: this.crumbs.length - 1 == ix,
          });
        } else {
          items.push({
            folder_uuid: crumb.value,
            text: crumb.name,
            active: this.crumbs.length - 1 == ix,
          });
        }
      }
      return items;
    },
    folderKey(){
      return "listFolders:" +  this.value;
    }
  },
  watch: {
    value: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getFolders();
      }
    },
  },
  mounted: function () {
    this.getFolders();
  },
};
</script>
<style>
</style>
