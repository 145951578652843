var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "section",
        { key: _vm.folderKey },
        [
          _c(
            "b-breadcrumb",
            { staticClass: "mt-3 mb-0" },
            _vm._l(_vm.breadcrumbs, function (item, index) {
              return _c(
                "b-breadcrumb-item",
                {
                  key: index,
                  attrs: { href: "#", active: item.active },
                  on: {
                    click: function ($event) {
                      return _vm.clickedFolder(item.folder_uuid)
                    },
                  },
                },
                [_vm._v(_vm._s(item.text))]
              )
            }),
            1
          ),
          _c(
            "b-list-group",
            { staticClass: "mt-2" },
            [
              _vm.breadcrumbs.length > 1
                ? _c(
                    "b-list-group-item",
                    {
                      staticClass: "clickable",
                      on: { click: _vm.goBackOneLevel },
                    },
                    [_vm._v(" .. ")]
                  )
                : _vm._e(),
              _vm._l(_vm.folders, function (item, index) {
                return _c(
                  "b-list-group-item",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    key: index,
                    staticClass: "clickable",
                    attrs: { title: item.name },
                    on: {
                      click: function ($event) {
                        return _vm.clickedFolder(item.folder_uuid)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fal fa-2x fa-folder" }),
                    _vm._v(" " + _vm._s(_vm.Truncate(item.name, 55)) + " "),
                  ]
                )
              }),
              _vm.folders.length == 0
                ? _c("b-list-group-item", [
                    _vm._v(_vm._s(_vm.$t("FILESTORAGE.NO_FOLDERS_TO_SAVE_TO"))),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }